$spot-colour: #20a8d8;
$light-spot-colour: #62c2e3;
$white: #ffffff;
$black: #000000;


:root {
    --bd-dark-green-colour: #012a13;
    --bd-green-colour: #009540;
    --bd-mid-green-colour: #9BCFB2;
    --bd-light-green-colour: #CEE6D7;
    --bd-lighter-green-colour: #f4faf6;

    --bd-blue-colour: #58ABC6;
    --bd-mid-blue-colour: #C6DDEA;
    --bd--light-blue-colour: #E3EEF4;

    --bd-purple-colour: #4D1C6B;
    --bd-mid-purple-colour: #8E2F89;
    --bd-light-purple-colour: #E5D7EB;

    --bd-teal-colour: #00899D;
    --bd-mid-teal-colour: #46B9B0;
    --bd-light-teal-colour: #C6E4E1;

    --bd-element-bg: #f7f7f7;

}

/* You can add global styles to this file, and also import other style files */


@import "@ng-select/ng-select/themes/default.theme.css";

$font-family-base: Nunito Sans;
$headings-font-family: Nunito Sans;
$font-size-base: 0.8125rem;
$line-height-base: 1.46;

$breadcrumb-divider: quote(">");

$link-decoration: none;
$link-hover-decoration: underline;

$progress-bar-color: $white;
$progress-bar-bg: var(--bd-green-colour);

/* Importing Bootstrap SCSS file. */

@import 'bootstrap/scss/bootstrap';


@include color-mode(dark) {
    --bd-element-bg: #2b3035;

    .search-box-gradient {
        background: transparent linear-gradient(90deg, #8D989F 0%, #90A79A 100%) 0% 0% no-repeat padding-box !important;
    }

    .bond-emphasis {
        background-color: var(--bd-green-colour) !important;
    }
}

.bond-emphasis {
    background-color: var(--bd-light-green-colour) !important;
}


.search-box-gradient {
    background: transparent linear-gradient(90deg, #E3EEF4 0%, #CCEAD9 100%) 0% 0% no-repeat padding-box;
}

.navbar-brand svg {
    // width: 190px;
    max-width: 190px;
    height: 34px;
}

path.letterClass {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 4;
}

@include color-mode(dark) {

    path.letterClass {
        stroke: #ffffff !important;
        fill: #000000 !important;
    }
}

html,
body {
    height: 100%;
}

.shepherd-element.shepherd-has-title[data-popper-placement^=bottom]>.shepherd-arrow:before {
    background-color: var(--bd-green-colour);
}

.throbber {

	box-shadow: 0 0 0 0 rgba(0, 95, 40, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 95, 40, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 95, 40, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 95, 40, 0);
	}
}

.date-divider {
    margin: 0.5em 0;
    border-bottom: 1px solid $secondary;
}

.story {
    margin-bottom: 0.5em;
    h2 {
        margin-bottom: 0px;
        font-size: 1.25rem;
        font-weight: 300;

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    p {
        margin: 0;
    }
}

.a_without_href {
    cursor: pointer;
}

.clickable {
    cursor: pointer;
    user-select: none;
}

.export-button {
    float: right;
    color: $white;
    background-color: $spot-colour;
    cursor: pointer;
    user-select: none;

    .fa-file-excel-o {
        color: $white;
    }
}

.line-through {
    text-decoration: line-through;
}

.pagetable {
    thead th:first-child {
        padding-left: 0;
    }

    thead th:not(:last-child) {
        padding-right: 0;
    }

    tbody {
        position: relative;

        tr.clickable {
            //position: sticky;
            top: -1px;
            left: 0;
            z-index: 1;

            &:not(.collapsed) {
                //background-color: #ccc;
                transition: background-color 1s linear;
            }

            &.collapsed {
                //background-color: #fff;
                transition: background-color 1s linear;
            }
        }
    }
}

.sticky-header {
    position: relative;

    th {
        position: sticky;
        top: 0;
        z-index: 4;
        &:first-child {
            padding-left: 25px;
        }
    }

    td:first-child {
        padding-left: 25px;
    }
}


.definitions {

    td,
    th {
        padding: 0;
    }

    th {
        background-color: var(--bs-body-bg);
        color: var(--bs-body-color) !important;
    }

    td:first-child:not(.plain) {
        font-weight: bold;
        padding-right: 10px;
    }
}

.column-header {
    margin: 0;
    width: 100%;
    font-weight: 300 !important;
    border-bottom: 1px var(--bs-body-color) solid;
    font-size: 20px;
}

.one-line-first-column td:first-child {
    white-space: nowrap;
    padding-right: 8px;
}

td.row-toggle {
    padding-left: 0;
    padding-right: 0;
    width: 22px;
}

td.add-format ul {
    padding: 0;
}

.white-tooltip {
    opacity: 1 !important;

    .tooltip-inner {
        background-color: #ffffff;
        color: var(--bs-body-color);
        font-size: 12px;
        text-align: left;
        border: 1px var(--bs-body-color) solid;
        border-radius: 8px;
    }
}


.comma-list,
.semicolon-list {
    display: inline;
    list-style: none;
    padding: 0px;

    li {
        display: inline;
    }

    li:last-child::after {
        content: "";
    }
}

.comma-list {
    li::after {
        content: ", ";
    }
}

.semicolon-list {
    li::after {
        content: "; ";
    }

}

.shepherd-header {
    h3 {
        margin: 0;
    }
}

.shepherd-text {
    overflow: auto !important;
}

.column-options {
    &>thead {
        border: none;

        tr {
            border: none;

            // border: 1px $black solid;
            th {
                border: 1px $black solid;

                &.row-toggle {
                    width: 45px;
                    background-color: $white;
                    white-space: nowrap;
                    color: $black;
                    padding-right: 11px;
                }

                &:nth-child(2) {
                    padding-left: 5px;
                }
            }
        }
    }
}

td.row-toggle {
    width: 20px;
}

table.fixed-layout {
    table-layout: fixed;
}

table.with-expansion {
    $expansionSize: 16px;

    thead {
        th:first-child {
            padding-left: calc(1rem + $expansionSize);
        }
    }

    tbody {
        td.expanding {
            width: $expansionSize;
            padding-left: 0;
        }
    }
}

th.left-aligned-name,
td.left-aligned-name {
    padding-left: 0;
    vertical-align: top;

    >div {
        display: inline-block;
    }

    >div>i {
        display: inline-block;
    }

    >div>span {
        display: inline-block;
        white-space: nowrap;
    }
}

.mw-75 {
    max-width: 75%;
}

.help-button {
    font-size: 1.5em;
    float: right;
    margin: -8px 0 0 0;
    padding-top: 0;
    color: $spot-colour;
}


.loading-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    min-height: 100vh;
    background-color: var(--bs-gray-400);
    opacity: 0.5;
    z-index: 2000;

    .progress-container {
        width: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $white;
        height: 59px;
        padding: 20px;
        border-radius: 8px;
        border: 2px $black solid;
    }
}


.column-chooser {
    background-color: var(--bs-body-bg);
    color: var(--bs-body-color);
    border: 1px var(--bs-body-color) solid;
    font-weight: 100;

    .col,
    .col-auto {
        padding-left: 1em;
        border-left: 1px var(--bs-body-color) solid;

        .option-group-column-header {
            border-bottom: 1px var(--bs-body-color) solid;
            white-space: nowrap;
        }

        &:first-child {
            padding-left: 2em;
            border: none;
        }
    }
}

.column-sorter {
    display: inline-block;
    cursor: pointer;
    padding-right: 0.75em;
    white-space: nowrap;
    width: 100%;
    &:hover {
        // background-color: #888;
        color: var(--bd-green-colour);
    }

}

.charts-details {
    summary {
        list-style: none;
        user-select: none;

        &:before {
            content: "\f054";
            font-family: FontAwesome;
            display: inline-block;
            margin-left: -1.1em;
            width: 1.1em;
        }
    }

    &[open]>summary:before {
        content: "\f078";
    }
}

.graph-container {
    margin-bottom: 1em;
    white-space: nowrap;

    .heading {
        border: 1px $black solid;
        float: left;
        width: 20px;
        min-height: calc(100% - 5px);

        .text {
            transform: rotate(90deg);
            transform-origin: 0 0;
            font-size: 1em;
            font-weight: 100;
            // color: $white;
            white-space: nowrap;
            line-height: 1em;
            position: absolute;
            top: 3px;
            left: 31px;
            text-transform: uppercase;

            &.small {
                font-size: 0.9em;
            }
        }
    }

    highcharts-chart {
        border-top: 1px $black solid;
        border-bottom: 1px $black solid;
        width: calc(100% - 14px);
        display: inline-block;
        overflow: visible;

        &.bar-chart {
            width: calc(50% - 14px);
        }

        &:last-child {
            border-right: 1px $black solid;
            margin-left: -3px;
        }
    }
}


.bd-green-colour {
    color: var(--bd-green-colour) !important
}

.bd-mid-green-colour {
    color: var(--bd-mid-green-colour)
}

.bd-bg-green-colour {
    background-color: var(--bd-green-colour) !important;
}

.border-bd-green-colour {
    border-color: var(--bd-green-colour) !important;

    .card-body {
        color: var(--bs-body-color) !important;
    }
}

a {
    color: var(--bd-green-colour)
}

.btn-gradient {
    border-color: var(--bd-green-colour);
    background: transparent linear-gradient(66deg, var(--bd-blue-colour) 0%, var(--bd-green-colour) 100%) 0% 0% no-repeat padding-box;
    color: var(--bs-white);
}

.btn-purple-gradient {
    border-color: var(--bd-purple-colour);
    background: transparent linear-gradient(66deg, var(--bd-purple-colour), var(--bd-mid-purple-colour) 100%) 0% 0% no-repeat padding-box;
    color: var(--bs-white);
}

.btn-teal-gradient {
    border-color: var(--bd-teal-colour);
    background: transparent linear-gradient(66deg, var(--bd-teal-colour), var(--bd-mid-teal-colour) 100%) 0% 0% no-repeat padding-box;
    color: var(--bs-white);
}

.btn-blue-gradient {
    border-color: var(--bd-blue-colour);
    background: var(--bd-blue-colour);
    color: var(--bs-white);

    &:hover {
        border-color: var(--bd-blue-colour);
        color: var(--bs-black);
    }
}

.btn-bankruptcydata {
    border-color: var(--bd-green-colour);
    background-color: #ffffffff;

    &:hover {
        background-color: var(--bd-green-colour) !important;
        border-color: var(--bs-black) !important;
        color: var(--bs-body-bg) !important;
    }

    &.active {
        border-color: var(--bd-green-colour);
        background-color: var(--bd-green-colour);
        color: var(--bs-white) ;

        &:hover {
            background-color: var(--bs-body-bg);
            border-color: var(--bs-black);
            color: var(--bd-green-colour);
        }
    }
}

@include color-mode(dark) {

    .btn-bankruptcydata {
        border-color: var(--bd-green-colour);
        background-color: var(--bs-black);

        &:hover {
            background-color: var(--bd-green-colour) !important;
            border-color: var(--bs-white) !important;
            color: var(--bs-body-bg) !important;
        }
        &.active {
            border-color: var(--bd-green-colour);
            background-color: var(--bs-white);
            color: var(--bd-green-colour) ;

            &:hover {
                background-color: var(--bd-green-colour);
                border-color: var(--bs-white);
                color: var(--bs-white);
            }
        }
    }

    .btn-blue-gradient {
        border-color: var(--bd-blue-colour);
        background: var(--bd-blue-colour);
        color: var(--bs-white);

        &:hover {
            border-color: var(--bd-blue-colour);
            color: var(--bs-black);
        }
    }

}

.btn-blue-bankruptcydata {
    border-color: var(--bd-blue-colour);
    background-color: #ffffffff;

    &:hover {
        background-color: var(--bd-blue-colour);
        border-color: var(--bs-black);
        color: var(--bs-body-bg);
    }
}

.btn-bankruptcydata-prev-next {
    border: none;
    background-color: #ffffff00;

    &:hover {
        color: var(--bd-green-colour);
    }
}

.btn-outline-bankruptcydata {
    --bs-btn-color: var(--bd-green-colour);
    --bs-btn-border-color: var(--bd-green-colour);
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: var(--bd-green-colour);
    --bs-btn-hover-border-color: var(--bd-green-colour);
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: var(--bd-green-colour);
    --bs-btn-active-border-color: var(--bd-green-colour);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--bd-green-colour);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--bd-green-colour);
    --bs-gradient: none;

    &:checked {
        color: var(--bs-btn-active-color);
        background-color: var(--bs-btn-active-bg);
        border-color: var(--bs-btn-active-border-color);
    }
}

.show-all {
    float: right;
    height: 22px;
    padding: 0.1rem 0.5rem;
    font-size: .75rem;
}

.collapsible {
    display: flex;

    &::before {
        min-width: 10px;
        content: " ";
        display: flex;
        border-left: 2px var(--bd-green-colour) solid;
    }

}

.loading-screen-parent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    min-height: 100vh;

    .loading-screen {
        background-color: var(--bs-gray-400);
        opacity: 0.5;
        z-index: 2000;
    }

    .progress-container {
        z-index: 2001;
        width: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--bs-body-bg);
        height: 59px;
        padding: 20px;
        border-radius: 8px;
        border: 2px var(--bs-body-color) solid;
    }
}

html,
body {
    height: 100%;
}

// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }




.linkedin-popover {
    max-width: fit-content;

    .popover-body {
        padding: 0;
    }
}


.ngr-scrollbar {
    --scrollbar-size: 8px !important;
    --scrollbar-thumb-color: var(--bd-mid-green-colour) !important;
    --scrollbar-thumb-hover-color: var(--bd-green-colour) !important;
    --scrollbar-border-radius: 4px !important;

    .ng-scrollbar-track {
        z-index: 20;
    }
}

.list-group-item {

    &.date-header {
        margin-right: 5px;
        position: sticky;
        top: 0px;
        align-self: flex-start;
        z-index: 3;
        width: 100%;
        font-weight: bold;
        border-top: 2px var(--bs-black) solid;
        border-bottom: 2px var(--bs-black) solid;
    }
}


.results-header {

    display: block;
    position: sticky;
    z-index: 22;
    top: 0;
    z-index: 1;
    margin: 10px 0 5px 0;
    padding: 10px 0;
    height: 36px;

    button:not(.update-docket) {
        width: 250px;
    }

    .__back {

        color: var(--app-color-primary);
        margin-left: var(--app-spacing--lg);
        font-size: var(--app-text-size--md);
        outline: none;
        cursor: pointer;
        user-select: none;

        &:hover {
            outline: none;
        }
    }

    h1 {
        padding: var(--app-spacing--xs) var(--app-spacing--lg) var(--app-spacing--md);
        margin-bottom: 0;
        margin-top: 0;
        border-bottom: var(--app-border);
        font-size: var(--app-text-size--xl);
        position: sticky;
        color: var(--app-color-text-primary);
        top: 0;

    }
}

.nav-underline {
    margin-bottom: 8px;
    --bs-nav-underline-border-width: 0.25rem;
    --bs-nav-underline-link-active-color: var(--bd-green-colour);
    --bs-nav-link-hover-color: var(--bd-green-colour);

    a,
    button {
        color: var(--bd-green-colour);
    }

    button {
        //color: var(--bs-primary-text-emphasis);

        &::hover {
            color: var(--bs-primary-text-emphasis);
        }
    }

    .situation_type {
        font-weight: bold;
        text-transform: capitalize;
        display: inline-block;
        margin-top: 7px;
    }
}

.form-check-input:checked,
.form-check-input:indeterminate {
    background-color: var(--bd-green-colour) !important;
}

.tr-hover {
    cursor: pointer;

    &:hover>* {
        background-color: var(--bd-mid-green-colour);
    }

    .td-hover {
        color: var(--bd-green-colour);

        &:hover {
            text-decoration: underline;
        }
    }
}

.truncate-organizations {
    width: 225px;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.shepherd-header {
    background-color: var(--bd-green-colour) !important;

    .shepherd-title {
        color: #fff;
        font-weight: bold;
    }

    .shepherd-cancel-icon {
        color: #fff !important;
    }
}

.document-description-abbreviated {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical
}

.search-snippets {
    font-style: italic;
}

.ng-select-multiautocomplete {
    .ng-dropdown-panel {
        width: auto;
    }
}

.range-chooser {
    border: 0;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;

    option {
        text-align: right;
        text-align-last: right;
    }
}

.mat-mdc-card {
    background-color: var(--bs-body-bg) !important;
}

@import "bootstrap-print-css/css/bootstrap-print.min.css";

@media print {
    @page {
        size: letter;
    }

    app-root {
        height: auto !important;
        overflow: visible !important;
    }

    .__master,
    filtering-search {
        display: none !important;
    }

}

html,
body {
    height: 100%;
}

body {
    margin: 0
}


.diffuse-backdrop {
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1 !important;
}


@import "../node_modules/angular-calendar/scss/angular-calendar";

.cal-month-view {
    .cal-cell-top {
        min-height: 36px;
        flex: 0;
    }

    .cal-day-cell {
        &.cal-today {
            .cal-day-number {
                font-size: inherit !important;
                font-weight: bold;
            }
        }
    }
}

@include color-mode(dark) {
    // First define some global color variables for your app, these are just for the demo, they can be anything you like
    $bg-dark-primary: #1f262d;
    $bg-dark-secondary: #394046;
    $bg-active: #2c343a;
    $text-color: #d5d6d7;
    $border-color: rgb(0 0 0 / 60%);

    // Call the calendar mixin with a sass color map of your theme. Every property is optional.
    // For a list of all variables and how they are used,
    // see https://github.com/mattlewis92/angular-calendar/tree/main/projects/angular-calendar/src/variables.scss
    @include cal-theme((bg-primary: $bg-dark-primary,
            bg-secondary: $bg-dark-secondary,
            weekend-color: indianred,
            bg-active: $bg-active,
            border-color: $border-color,
            gray: $bg-dark-secondary,
            today-bg: $bg-dark-secondary,
            event-color-primary: $bg-dark-secondary,
        ));

    // How to override a non themable property, this was copied from chrome -> inspect element -> styles panel
    .cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
        opacity: 0.15;
    }
}

.popover-large {
    max-width: 40vw !important;
}

.pdf-link {
    white-space: nowrap;
    display: inline-block;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    cursor: pointer;
}

.follower {
    div {
        float: left;
        padding-right: 6px;
    }
    i-bs {
        display: block;
        margin-top: -2px;
        color: #888888;
        width: 16px;
        float: left;

        &.active {
            color: var(--bd-green-colour) ;
        }
    }

    .stretched-link::after {
        right: 48px;
    }
}



.bd-green-tooltip {
    .tooltip-inner {
        background-color: var(--bd-green-colour);
        font-size: 125%;
    }
    &.bs-tooltip-end .tooltip-arrow::before {
        border-right-color: var(--bd-green-colour);
    }
    &.bs-tooltip-start .tooltip-arrow::before {
        border-left-color: var(--bd-green-colour);
    }
    &.bs-tooltip-top .tooltip-arrow::before {
        border-top-color: var(--bd-green-colour);
    }
    &.bs-tooltip-bottom .tooltip-arrow::before {
        border-bottom-color: var(--bd-green-colour);
    }
}
.bd-green-popover {
    border-color: var(--bd-light-green-colour);
    .popover-body {
        color:#000000;
        background-color: var(--bd-light-green-colour);
        font-size: 125%;
    }
    .popover-arrow::after,
    .popover-arrow::before {
        border-top-color: var(--bd-light-green-colour);
    }
}


@import 'angular-calendar/scss/angular-calendar.scss';
@include color-mode(dark) {
    $bg-dark-primary: #1f262d;
    $bg-dark-secondary: #394046;
    $bg-active: #2c343a;
    $text-color: #d5d6d7;
    $border-color: var(--bd-green-colour);

    // Call the calendar mixin with a sass color map of your theme. Every property is optional.
    // For a list of all variables and how they are used,
    // see https://github.com/mattlewis92/angular-calendar/tree/main/projects/angular-calendar/src/variables.scss
    @include cal-theme((bg-primary: $bg-dark-primary,
            bg-secondary: $bg-dark-secondary,
            weekend-color: indianred,
            bg-active: $bg-active,
            border-color: $border-color,
            gray: $bg-dark-secondary,
            today-bg: $bg-dark-secondary,
            event-color-primary: $bg-dark-secondary,
        ));
}